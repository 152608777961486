import { render, staticRenderFns } from "./document-picker-list-item-ref.vue?vue&type=template&id=81a0fb8a&scoped=true&"
import script from "./document-picker-list-item-ref.vue?vue&type=script&lang=js&"
export * from "./document-picker-list-item-ref.vue?vue&type=script&lang=js&"
import style0 from "./document-picker-list-item-ref.vue?vue&type=style&index=0&id=81a0fb8a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a0fb8a",
  null
  
)

export default component.exports